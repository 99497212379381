import httpManager from "../axios/httpManager";

export function getBoardList  (search, pageNum, pageSize) {
    return httpManager.post('/board/list' + '?pageNum=' + pageNum + '&pageSize=' + pageSize, search);
}
export function updateBoard  (board) {
    return httpManager.post('/board/update', board);
}
export function saveBoard  (board) {
    return httpManager.post('/board/save', board);
}
export function deleteBoard  (id) {
    return httpManager.get('/board/delete?id=' + id);
}
export function getBoardById  (id) {
    return httpManager.get('/board/get_by_id?id=' + id);
}