import httpManager from "../axios/httpManager";

export function getPopupList (entity) {
    return httpManager.post('/popup/list', entity);
}
export function getPopupById (id) {
    return httpManager.get('/popup/get_by_id?id=' + id)
}
export function deletePopup (id) {
    return httpManager.get('/popup/delete?id=' + id)
}
export function updatePopup (entity) {
    return httpManager.post('/popup/update', entity);
}
export function copyPopup (id) {
    return httpManager.get('/popup/copy?id=' + id);
}